const ENDPOINTS = {
    development: "https://api-sandbox.core.koinz.app",
    test: "https://api-sandbox.core.koinz.app",
    production: "https://api-sandbox.core.koinz.app"
  };
  const ADMIN_ENDPOINTS = {
    development: "https://api-sandbox.admin.shopxgo.com",
    test: "https://api-sandbox.admin.shopxgo.com",
    production: "https://api-sandbox.admin.shopxgo.com"
  };
  const PICKUP_ENDPOINTS = {
    development: "https://api-sandbox.order.koinz.app",
    test: "https://api-sandbox.order.koinz.app",
    production: "https://api-sandbox.order.koinz.app"
  };
  const imagesURL = "https://api-sandbox.core.koinz.app";
  const VERSION_FIELD = "cashier";
  const ENABLE_MOBILE = false;
  const PUSHER_API_KEY = "3eb07dbec0b25a50c5f1";
  module.exports = {
    ENDPOINTS,
    ENABLE_MOBILE,
    VERSION_FIELD,
    imagesURL,
    PUSHER_API_KEY,
    ADMIN_ENDPOINTS,
    PICKUP_ENDPOINTS
  };
